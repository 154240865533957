import React from "react"
import { Link } from "gatsby"
import {
  FooterSocialVertical,
  FooterTextVertical,
  GridBoxShell,
  GridSpacer,
  Grid5Footer,
  IconFacebook,
  IconInstagram,
  IconLinkedin,
  IconTwitter,
  LinkIcon,
} from "@thedgbrt/logology-ui-components"

const FooterMain = () => (
  <>
    <GridSpacer desktop={156} mobile={106} tablet={106} type={5} />
    <Grid5Footer
      copyright="©&nbsp;logology"
      socialLinks={[
        <LinkIcon href="https://twitter.com/logologydesign" target="_blank">
          <IconTwitter linkBoxV />
        </LinkIcon>,
        <LinkIcon
          href="https://www.instagram.com/logologydesign/"
          target="_blank"
        >
          <IconInstagram linkBoxV />
        </LinkIcon>,
        <LinkIcon
          href="https://www.linkedin.com/company/logology/"
          target="_blank"
        >
          <IconLinkedin linkBoxV />
        </LinkIcon>,
        <LinkIcon
          href="https://www.facebook.com/logologydesign/"
          target="_blank"
        >
          <IconFacebook linkBoxV />
        </LinkIcon>,
      ]}
    >
      <span>
        <Link to="/signin/" className="hide_desktop">
          log in
        </Link>
      </span>
      <span>
        <Link className="hide_desktop" to="/testimonials/">
          testimonials
        </Link>
      </span>
      <Link to="/blog/">blog</Link>
      <Link to="/support/">support</Link>
      <Link to="/become-an-affiliate/">become an affiliate</Link>
      <Link to="/privacy-policy/">privacy policy</Link>
      <Link to="/terms-and-conditions/">terms and conditions</Link>
    </Grid5Footer>
  </>
)

const FooterSocial = () => (
  <FooterSocialVertical>
    <LinkIcon href="https://twitter.com/logologydesign" target="_blank">
      <IconTwitter linkBox />
    </LinkIcon>
    <LinkIcon href="https://www.instagram.com/logologydesign/" target="_blank">
      <IconInstagram linkBox />
    </LinkIcon>
    <LinkIcon href="https://www.linkedin.com/company/logology/" target="_blank">
      <IconLinkedin linkBox />
    </LinkIcon>
    <LinkIcon href="https://www.facebook.com/logologydesign/" target="_blank">
      <IconFacebook linkBox />
    </LinkIcon>
  </FooterSocialVertical>
)

const FooterText = () => (
  <GridBoxShell colSpans={[[1, 1], [1, 1], [1, 1]]}>
    <FooterTextVertical>©&nbsp;logology</FooterTextVertical>
  </GridBoxShell>
)
export { FooterMain, FooterSocial, FooterText }
