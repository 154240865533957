const initialState = {
  statusById: {},
}

const brandPreview = (state = initialState, action) => {
  switch (action.type) {
    case "BRAND_PREVIEW_FETCH_START":
      return {
        ...state,
        statusById: {
          ...state.statusById,
          [action.id]: "waiting_for_response",
        },
      }

    case "BRAND_PREVIEW_FETCH_SUCCESS":
      return {
        ...state,
        statusById: {
          ...state.statusById,
          [action.id]: "ok",
        },
        [action.id]: action.payload,
      }

    case "BRAND_PREVIEW_FETCH_ERROR":
      return {
        ...state,
        statusById: {
          ...state.statusById,
          [action.id]: "error",
        },
      }

    default:
      return state
  }
}

export default brandPreview
