import { createStore, applyMiddleware } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import localforage from "localforage"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import rootReducers from "./reducers/index"

let storage = localforage
storage.setDriver(localforage.LOCALSTORAGE)

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["questionnaire"],
}

const persistedReducer = persistReducer(persistConfig, rootReducers)

// create store
const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
)

const persistor = persistStore(store)

export { persistor, store }
