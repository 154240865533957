const parsePayload = payload => ({
  activityId: payload.activityId !== null ? payload.activityId : "",
  activityName: payload.activityName !== null ? payload.activityName : "",
  activitySelectStage:
    payload.activitySelectStage !== null ? payload.activitySelectStage : null,
  startupName: payload.startupName !== null ? payload.startupName : "",
  answers:
    payload.answers !== null
      ? payload.answers
      : {
          _1: null,
          _2: null,
          _3: null,
          _4: null,
          _5: null,
          _6: null,
          _7: null,
          _8: null,
          _9: null,
          _10: null,
          _11: null,
        },
})

const preparePayload = payload => {
  let cleanPayload = Object.assign({}, payload)

  if (payload.activityId === "") cleanPayload.activityId = null
  if (payload.activityName === "") cleanPayload.activityName = null
  if (payload.activitySelectStage === "")
    cleanPayload.activitySelectStage = null
  if (payload.startupName === "") cleanPayload.startupName = null
  cleanPayload.answers = payload.answers

  return cleanPayload
}

export { parsePayload, preparePayload }
