import React from "react"
import {
  Grid2QuestionnaireHeader,
  GridWrapper,
  GlobalStyle,
} from "@thedgbrt/logology-ui-components"

const OnboardingLayout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <GridWrapper type={2}>
        <Grid2QuestionnaireHeader
          navLinks={[{ label: "back to main site", to: "/" }]}
        />
        {children}
      </GridWrapper>
    </>
  )
}

export default OnboardingLayout
