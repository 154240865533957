const initialState = {
  itemDetails: {},
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case "SAVED_PROPOSAL_INIT":
      return {
        ...state,
        itemDetails: {
          ...state.itemDetails,
          [action.id]: {
            fetchStatus: "unknown",
          },
        },
      }

    case "SAVED_PROPOSAL_FETCH_START":
      return {
        ...state,
        itemDetails: {
          ...state.itemDetails,
          [action.id]: {
            fetchStatus: "waiting_for_response",
          },
        },
      }
    case "SAVED_PROPOSAL_FETCH_ERROR":
      return {
        ...state,
        itemDetails: {
          ...state.itemDetails,
          [action.id]: {
            fetchStatus: "error",
          },
        },
      }
    case "SAVED_PROPOSAL_FETCH_SUCCESS":
      return {
        ...state,
        itemDetails: {
          ...state.itemDetails,
          [action.id]: {
            fetchStatus: "ok",
            data: action.payload,
          },
        },
      }

    default:
      return state
  }
}
export default user
