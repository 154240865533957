import React from "react"
import { connect } from "react-redux"
import {
  Grid3Header,
  GridContainer,
  GridWrapper,
  Grid2QuestionnaireHeader,
  makeRem,
} from "@thedgbrt/logology-ui-components"
import FooterAdmin from "../../components/FooterAdmin"

const AccountWrapper = ({ isLoggedIn, children, type }) => {
  if (type === "account-minimal") return children
  else if (type === "account-onboarding") {
    // questionnaire
    return (
      <GridWrapper type={2}>
        <Grid2QuestionnaireHeader
          navLinks={[
            { label: "back to proposals", to: "/myaccount/proposals/" },
            { label: "settings", to: "/myaccount/settings/" },
          ]}
        />
        {children}
      </GridWrapper>
    )
  } else if (type === "account-simple") {
    // tailoring in progress
    return (
      <GridWrapper type={3}>
        <Grid3Header />
        <GridContainer
          rowsDesktop={[makeRem(43)]}
          rowsMobile={[makeRem(30)]}
          rowsTablet={[makeRem(30)]}
          type={3}
        />
        {children}
        <GridContainer
          rowsDesktop={[makeRem(150)]}
          rowsMobile={[makeRem(150)]}
          rowsTablet={[makeRem(150)]}
          type={3}
        />
      </GridWrapper>
    )
  } else {
    // proposals
    return (
      <GridWrapper type={4} withFixedHeader={type === "account-fixed-header"}>
        {children}
        <FooterAdmin isLoggedIn={isLoggedIn} />
      </GridWrapper>
    )
  }
}

export default connect(({ user }) => ({
  colorId: user.profile.colorId,
  personaId: user.profile.personaId,
  isLoggedIn: user.isLoggedIn,
}))(AccountWrapper)
