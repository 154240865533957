import React from "react"
import {
  Grid3Header,
  GridContainer,
  GridWrapper,
  GlobalStyle,
  makeRem,
} from "@thedgbrt/logology-ui-components"

const SignupLayout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <GridWrapper type={3}>
        <Grid3Header />
        <GridContainer
          rowsDesktop={[makeRem(43)]}
          rowsMobile={[makeRem(30)]}
          rowsTablet={[makeRem(30)]}
          type={3}
        />
        {children}
        <GridContainer
          rowsDesktop={[makeRem(150)]}
          rowsMobile={[makeRem(150)]}
          rowsTablet={[makeRem(150)]}
          type={3}
        />
      </GridWrapper>
    </>
  )
}

export default SignupLayout
