import React from "react"
import { Grid5Header } from "@thedgbrt/logology-ui-components"

const Header = () => (
  <Grid5Header
    fixed
    hrefLogo="/"
    items={[
      {
        label: "case studies",
        labelShort: "case studies",
        to: "/case-studies/",
        showMobile: true,
      },
      {
        label: "pricing",
        labelShort: "pricing",
        to: "/pricing/",
        showMobile: true,
      },
      {
        label: "testimonials",
        labelShort: "testim.",
        to: "/testimonials/",
        showMobile: false,
      },
      {
        label: "log in",
        labelShort: "log in",
        to: "/signin/",
        showMobile: false,
      },
      {
        label: "logos that match my values →",
        to: "/questionnaire/",
        labelShort: "logos that fit my values →",
        blackButton: true,
        showMobile: true,
      },
    ]}
  />
)

export default Header
