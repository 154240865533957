import React from "react"
import { Link } from "gatsby"
import { GridFooter, FooterLinks } from "@thedgbrt/logology-ui-components"

const FooterAdmin = ({ isLoggedIn }) => (
  <GridFooter type={3}>
    <FooterLinks>
      <span>© {new Date().getFullYear()} logology</span>
      {isLoggedIn === true ? (
        <>
          <Link to="/myaccount/settings/">my account</Link>
          <Link to="/myaccount/settings/change-name/">update brand name</Link>
        </>
      ) : (
        <Link to="/anonaccount/settings/change-name/">update brand name</Link>
      )}
      <Link to="/support/">support</Link>
      <Link to="/terms-and-conditions/">terms and conditions</Link>
      <Link to="/">back to main site</Link>
    </FooterLinks>
  </GridFooter>
)

export default FooterAdmin
