import { parsePayload } from "../helpers/questionnaire"

const initialState = {
  currentStep: 0,
  activityId: "",
  activityName: "",
  activitySelectStage: null,
  personaId: 0,
  persona: 0,
  personalityScores: {
    fetchStatus: "unknown",
    data: {},
  },
  saveStatus: "unknown",
  sectorId: "",
  startupName: "",
  answers: {
    _1: null,
    _2: null,
    _3: null,
    _4: null,
    _5: null,
    _6: null,
    _7: null,
    _8: null,
    _9: null,
    _10: null,
    _11: null,
  },
}

const questionnaire = (state = initialState, action) => {
  switch (action.type) {
    case "ANSWERS_FETCH_SUCCESS": {
      return {
        ...state,
        ...parsePayload(action.payload),
      }
    }
    case "SET_STARTUP_NAME":
      return {
        ...state,
        startupName: action.name,
      }
    case "SET_ACTIVITY":
      return {
        ...state,
        activityId: action.id,
      }
    case "SET_ACTIVITY_NAME":
      return {
        ...state,
        activityName: action.name,
      }
    case "SET_ACTIVITY_SELECT_STAGE":
      return {
        ...state,
        activitySelectStage: action.value,
      }

    case "SET_PERSONA":
      return {
        ...state,
        persona: action.personaId,
      }
    case "UPDATE_ANSWER":
      return {
        ...state,
        answers: {
          ...state.answers,
          [action.questionId]: action.answerId,
        },
      }
    case "NEXT_STEP":
      return {
        ...state,
        currentStep: state.currentStep + 1,
      }
    case "PREVIOUS_STEP":
      return {
        ...state,
        currentStep: state.currentStep - 1,
      }

    case "ANSWERS_SAVE_START": {
      return {
        ...state,
        saveStatus: "started",
      }
    }

    case "ANSWERS_SAVE_SUCCESS": {
      return {
        ...state,
        saveStatus: "success",
      }
    }

    case "PERSONALITY_SCORES_FETCH_START": {
      return {
        ...state,
        personalityScores: {
          fetchStatus: "started",
          data: {},
        },
      }
    }

    case "PERSONALITY_SCORES_FETCH_SUCCESS": {
      return {
        ...state,
        personalityScores: {
          fetchStatus: "ok",
          data: action.payload,
        },
      }
    }

    case "PERSONALITY_SCORES_FETCH_ERROR": {
      return {
        ...state,
        personalityScores: {
          fetchStatus: "error",
          data: {},
        },
      }
    }

    case "RESET_ANSWERS":
      return { ...initialState }
    case "REHYDRATE":
      return { ...action.restoredState }
    default:
      return state
  }
}

export default questionnaire
