const initialState = {}

const proposalAssocPreviews = (state = initialState, action) => {
  switch (action.type) {
    case "PROPOSAL_ASSOC_PREVIEW_INIT":
      return {
        ...state,
        [action.previewId1]: {
          ...state[action.previewId1],
          [action.previewId2]: { status: "unknown" },
        },
      }

    case "PROPOSAL_ASSOC_PREVIEW_FETCH_START":
      return {
        ...state,
        [action.previewId1]: {
          ...state[action.previewId1],
          [action.previewId2]: { status: "waiting_for_response" },
        },
      }

    case "PROPOSAL_ASSOC_PREVIEW_FETCH_SUCCESS":
      return {
        ...state,
        [action.previewId1]: {
          ...state[action.previewId1],
          [action.previewId2]: {
            status: "ok",
            data: { ...action.payload.preview },
          },
        },
      }

    case "PROPOSAL_ASSOC_PREVIEW_FETCH_ERROR":
      return {
        ...state,
        [action.previewId1]: { [action.previewId2]: { status: "error" } },
      }

    case "RESET_PROPOSAL_ASSOC_PREVIEWS":
      return initialState

    default:
      return state
  }
}

export default proposalAssocPreviews
