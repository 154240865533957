const initialState = {
  statusById: {},
  emptyCount: 0,
}

const proposalPreviews = (state = initialState, action) => {
  switch (action.type) {
    case "PROPOSAL_PREVIEW_INIT":
      return {
        ...state,
        statusById: {
          ...state.statusById,
          [action.conceptId]: "unknown",
        },
        [action.conceptId]: {},
      }

    case "PROPOSAL_PREVIEW_FETCH_START":
      return {
        ...state,
        statusById: {
          ...state.statusById,
          [action.conceptId]: "waiting_for_response",
        },
      }

    case "PROPOSAL_PREVIEW_FETCH_SUCCESS":
      return {
        ...state,
        statusById: {
          ...state.statusById,
          [action.conceptId]: "ok",
        },
        [action.conceptId]: action.payload,
      }

    case "PROPOSAL_PREVIEW_FETCH_ERROR":
      return {
        ...state,
        statusById: {
          ...state.statusById,
          [action.conceptId]: "error",
        },
      }

    case "PROPOSAL_PREVIEW_FETCH_EMPTY":
      return {
        ...state,
        emptyCount: state.emptyCount + 1,
      }

    case "RESET_PROPOSAL_PREVIEWS":
      return initialState

    default:
      return state
  }
}

export default proposalPreviews
