import { combineReducers } from "redux"
import brandPreviews from "./brandPreviews"
import proposals from "./proposals"
import proposalPreviews from "./proposalPreviews"
import proposalAssocPreviews from "./proposalAssocPreviews"
import proposalSaveList from "./proposalSaveList"
import questionnaire from "./questionnaire"
import savedProposals from "./savedProposals"
import user from "./user"

export default combineReducers({
  brandPreviews,
  questionnaire,
  proposals,
  proposalAssocPreviews,
  proposalPreviews,
  proposalSaveList,
  savedProposals,
  user,
})
