const initialState = {
  isLoggedIn: false,
  email: "",
  id: "",
  profile: {
    createStatus: "unknown",
    fetchStatus: "unknown",
    isComplete: false,
    personaId: null,
    colorId: null,
  },
  localProfile: {
    verifyFetchStatus: "unknown",
    isComplete: false,
  },
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case "SIGN_IN":
      return {
        ...state,
        isLoggedIn: true,
        email: action.email,
        id: action.id,
      }
    case "SIGN_IN_OPTIMISTIC":
      return {
        ...state,
        isLoggedIn: true,
        email: action.email,
        id: action.id,
      }
    case "SIGN_OUT":
      return {
        ...state,
        isLoggedIn: false,
        email: "",
      }
    case "PROFILE_SET_COLOR_SCHEME":
      return {
        ...state,
        profile: {
          ...state.profile,
          colorSchemeId: action.id,
        },
      }
    case "PROFILE_RESET_COLOR":
      return {
        ...state,
        profile: {
          ...state.profile,
          colorId: null,
        },
      }
    case "PROFILE_SAVE_COLOR_SUCCESS":
      return {
        ...state,
        profile: {
          ...state.profile,
          colorId: action.id,
        },
      }
    case "PROFILE_SAVE_PERSONA_SUCCESS":
      return {
        ...state,
        profile: {
          ...state.profile,
          personaId: action.id,
        },
      }
    case "PROFILE_INIT_START":
      return {
        ...state,
        profile: {
          ...state.profile,
          createStatus: "waiting_for_response",
        },
      }
    case "PROFILE_INIT_SUCCESS":
      return {
        ...state,
        profile: {
          ...state.profile,
          createStatus: "ok",
        },
      }
    case "PROFILE_INIT_ERROR":
      return {
        ...state,
        profile: {
          ...state.profile,
          createStatus: "error",
        },
      }
    case "PROFILE_FETCH_START":
      return {
        ...state,
        profile: {
          ...state.profile,
          fetchStatus: "waiting_for_response",
        },
      }

    case "PROFILE_CHECK_START":
      return {
        ...state,
        profile: {
          ...state.profile,
          createStatus: "waiting_for_response",
        },
      }

    case "PROFILE_CHECK_SUCCESS":
      return {
        ...state,
        profile: {
          ...state.profile,
          createStatus: "ok",
        },
      }

    case "PROFILE_CHECK_ERROR":
      return {
        ...state,
        profile: {
          ...state.profile,
          createStatus: "not_exists",
        },
      }
    case "PROFILE_FETCH_SUCCESS":
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.data,
          createStatus: "exists",
          fetchStatus: "ok",
        },
      }

    case "PROFILE_FETCH_ERROR":
      return {
        ...state,
        profile: {
          ...state.profile,
          fetchStatus: "error",
        },
      }
    case "PROFILE_FETCH_NOT_FOUND":
      return {
        ...state,
        profile: {
          ...state.profile,
          createStatus: "not_exists",
          fetchStatus: "ok",
        },
      }

    case "PROFILE_LOCAL_CHECK_START":
      return {
        ...state,
        localProfile: {
          ...state.localProfile,
          verifyFetchStatus: "waiting_for_response",
        },
      }

    case "PROFILE_LOCAL_CHECK_SUCCESS":
      return {
        ...state,
        localProfile: {
          ...state.localProfile,
          verifyFetchStatus: "ok",
          isComplete: true,
        },
      }

    case "PROFILE_LOCAL_CHECK_FAILURE":
      return {
        ...state,
        localProfile: {
          ...state.localProfile,
          verifyFetchStatus: "ok",
          isComplete: false,
        },
      }

    case "PROFILE_LOCAL_CHECK_ERROR":
      return {
        ...state,
        localProfile: {
          ...state.localProfile,
          verifyFetchStatus: "error",
          isComplete: false,
        },
      }

    case "RESET_PROFILE":
      return initialState

    default:
      return state
  }
}

export default user
