const initialState = {}

const proposalSaveList = (state = initialState, action) => {
  switch (action.type) {
    case "PROPOSAL_SAVE_LIST_INIT":
      return {
        ...state,
        ...action.payload,
      }

    case "PROPOSAL_SAVE_START":
      return {
        ...state,
        [action.symbolId]: {
          ...state[action.symbolId],
          error: false,
          status: "waiting_for_response",
        },
      }

    case "PROPOSAL_SAVE_SUCCESS":
      return {
        ...state,
        [action.symbolId]: {
          ...action.payload,
          status: "saved",
          error: false,
        },
      }

    case "PROPOSAL_SAVE_ERROR":
      return {
        ...state,
        [action.symbolId]: {
          ...state[action.symbolId],
          status: "unsaved",
          error: true,
        },
      }

    case "PROPOSAL_UNSAVE_START":
      return {
        ...state,
        [action.symbolId]: {
          ...state[action.symbolId],
          error: false,
          status: "waiting_for_response_unsave",
        },
      }

    case "PROPOSAL_UNSAVE_SUCCESS":
      let next = { ...state }
      delete next[action.symbolId]
      return next

    case "PROPOSAL_UNSAVE_ERROR":
      return {
        ...state,
        [action.symbolId]: {
          ...state[action.symbolId],
          status: "saved",
          error: true,
        },
      }

    case "RESET_PROPOSAL_SAVE_LIST":
      return initialState

    default:
      return state
  }
}

export default proposalSaveList
