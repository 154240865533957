import React from "react"
import MainLayout from "./main"
import OnboardingLayout from "./onboarding"
import SignupLayout from "./signup"

export default ({ children, location, pageContext }) => {
  if (pageContext.layout === "onboarding") {
    return <OnboardingLayout>{children}</OnboardingLayout>
  } else if (pageContext.layout === "signup") {
    return <SignupLayout>{children}</SignupLayout>
  }
  return (
    <MainLayout type={pageContext.sublayout} location={location}>
      {children}
    </MainLayout>
  )
}
