import React, { useEffect, useState } from "react"
import {
  BackgroundCircles,
  GridWrapper,
  GlobalStyle,
} from "@thedgbrt/logology-ui-components"
import AccountWrapper from "./accountWrapper"
import Header from "../../components/Header"
import { FooterMain } from "../../components/Footer"

const MainLayout = ({ children, location, type }) => {
  const [rendered, setRendered] = useState(false)
  const [animTriggerCount, setAnimTriggerCount] = useState(0)

  // only render circles on first mount, not on page change
  useEffect(() => {
    setRendered(true)
  }, [])

  // trigger circle animation on every update
  useEffect(() => {
    setAnimTriggerCount(animTriggerCount => ({
      ...animTriggerCount,
      animTriggerCount: animTriggerCount + 1,
    }))
  }, [location.pathname])

  // layout when viewing logged in pages
  if (type.startsWith("account")) {
    return (
      <>
        <GlobalStyle />
        <AccountWrapper type={type}>{children}</AccountWrapper>
      </>
    )
  }

  // regular layout
  return (
    <GridWrapper type={5} withFixedHeader>
      <div id="main-modal-root">
        <GlobalStyle />
        {rendered ? (
          <BackgroundCircles animTriggerCount={animTriggerCount} />
        ) : null}
        <Header />
        {children}
        <FooterMain />
      </div>
    </GridWrapper>
  )
}

export default MainLayout
