const initialState = {
  colors: {
    fetchStatus: "unknown",
    data: {
      meaning: "",
      color_schemes: {},
    },
  },
  itemDetails: {},
  itemList: {
    fetchStatus: "unknown",
    data: {},
  },
  itemPreviews: {},
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case "PROPOSAL_COLOR_FETCH_START":
      return {
        ...state,
        colors: {
          fetchStatus: "waiting_for_response",
          data: [],
        },
      }
    case "PROPOSAL_COLOR_FETCH_ERROR":
      return {
        ...state,
        colors: {
          fetchStatus: "error",
          data: [],
        },
      }
    case "PROPOSAL_COLOR_FETCH_SUCCESS":
      return {
        ...state,
        colors: {
          fetchStatus: "ok",
          data: action.payload,
        },
      }

    case "PROPOSAL_LIST_FETCH_START":
      return {
        ...state,
        itemList: {
          fetchStatus: "waiting_for_response",
          data: {},
        },

        itemDetails: {},
      }
    case "PROPOSAL_LIST_FETCH_ERROR":
      return {
        ...state,
        itemList: {
          fetchStatus: "error",
          data: {},
        },
      }
    case "PROPOSAL_LIST_FETCH_LIMIT_REACHED":
      return {
        ...state,
        itemList: {
          fetchStatus: "limit_reached",
          data: {},
        },
      }

    case "PROPOSAL_LIST_FETCH_SUCCESS":
      return {
        ...state,
        itemList: {
          fetchStatus: "ok",
          data: action.payload,
        },
      }

    case "PROPOSAL_SINGLE_FETCH_START":
      return {
        ...state,
        itemDetails: {
          ...state.itemDetails,
          [action.id]: {
            fetchStatus: "waiting_for_response",
          },
        },
      }
    case "PROPOSAL_SINGLE_FETCH_ERROR":
      return {
        ...state,
        itemDetails: {
          ...state.itemDetails,
          [action.id]: {
            fetchStatus: "error",
          },
        },
      }
    case "PROPOSAL_SINGLE_FETCH_SUCCESS":
      return {
        ...state,
        itemDetails: {
          ...state.itemDetails,
          [action.id]: {
            fetchStatus: "ok",
            data: action.payload,
          },
        },
      }
    case "RESET_PROPOSALS":
      return initialState

    default:
      return state
  }
}
export default user
